/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const getRandomImage = () => {
  const images = [
    "planets/_pr_planet_000.webp",
    "planets/_pr_planet_001.webp",
    "planets/_pr_planet_002.webp",
    "planets/_pr_planet_003.webp",
    "planets/_pr_planet_004.webp",
    "planets/_pr_planet_005.webp",
    "planets/_pr_planet_006.webp",
    "planets/_pr_planet_007.webp",
    "planets/_pr_planet_008.webp",
    "planets/_pr_planet_009.webp",
    "planets/_pr_planet_010.webp",
    "planets/_pr_planet_011.webp",
    "planets/_pr_planet_012.webp",
    "planets/_pr_planet_013.webp",
    "planets/_pr_planet_014.webp",
    "planets/_pr_planet_015.webp",
    "planets/_pr_planet_016.webp",
    "planets/_pr_planet_017.webp",
    "planets/_pr_planet_018.webp",
    "planets/_pr_planet_019.webp",
    "planets/_pr_planet_020.webp",
    "planets/_pr_planet_021.webp",
    "planets/_pr_planet_022.webp",
    "planets/_pr_planet_023.webp",
    "planets/_pr_planet_024.webp",
    "planets/_pr_planet_025.webp",
    "planets/_pr_planet_026.webp",
    "planets/_pr_planet_027.webp",
    "planets/_pr_planet_028.webp",
    "planets/_pr_planet_029.webp",
    "planets/_pr_planet_030.webp",
    "planets/_pr_planet_031.webp",
    "planets/_pr_planet_032.webp",
    "planets/_pr_planet_033.webp",
    "planets/_pr_planet_034.webp",
    "planets/_pr_planet_035.webp",
    "planets/_pr_planet_036.webp",
    "planets/_pr_planet_037.webp",
    "planets/_pr_planet_038.webp",
    "planets/_pr_planet_039.webp",
    "planets/_pr_planet_040.webp",
    "planets/_pr_planet_041.webp",
    "planets/_pr_planet_042.webp",
    "planets/_pr_planet_043.webp",
    "planets/_pr_planet_044.webp",
    "planets/_pr_planet_045.webp",
    "planets/_pr_planet_046.webp",
    "planets/_pr_planet_047.webp",
    "planets/_pr_planet_048.webp",
    "planets/_pr_planet_049.webp",

    "gasgiants/pr_gasgiants_007.webp",
    "gasgiants/pr_gasgiants_009.webp",
    "gasgiants/pr_gasgiants_010.webp",
    "gasgiants/pr_gasgiants_012.webp",
    "gasgiants/pr_gasgiants_020.webp",
    "gasgiants/pr_gasgiants_026.webp",
    "gasgiants/pr_gasgiants_034.webp",
    "gasgiants/pr_gasgiants_038.webp",
    "gasgiants/pr_gasgiants_042.webp",
    "gasgiants/pr_gasgiants_043.webp",
    "gasgiants/pr_gasgiants_046.webp",
    "gasgiants/pr_gasgiants_052.webp",
    "gasgiants/pr_gasgiants_054.webp",
    "gasgiants/pr_gasgiants_057.webp",
    "gasgiants/pr_gasgiants_074.webp",
    "gasgiants/pr_gasgiants_086.webp",
    "gasgiants/pr_gasgiants_095.webp",

    "stars/pr_stars_026.webp",
    "stars/pr_stars_106.webp",
    "stars/pr_stars_113.webp",
    "stars/pr_stars_121.webp",
    "stars/pr_stars_135.webp",
    "stars/pr_stars_137.webp",
    "stars/pr_stars_140.webp",
    "stars/pr_stars_241.webp",
    "stars/pr_stars_244.webp",
    "stars/pr_stars_247.webp",
    "stars/pr_stars_248.webp",
    "stars/pr_stars_249.webp",
    "stars/pr_stars_255.webp",
    "stars/pr_stars_263.webp",
  ]
  return images[Math.floor(Math.random() * images.length)]
}

function SEO({ title, description, meta, author, image, date, type = "website", lang = "en" }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteName
            author {
              name
              twitterHandle
            }
            copyrightDate
            basePath
            image
          }
        }
      }
    `,
  )

  title = title || site.siteMetadata.title
  description = description || site.siteMetadata.description
  image = image || getRandomImage()
  image = site.siteMetadata.basePath + image

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.siteName}`}
      link={[
        {
          rel: `schema.dcterms`,
          href: `http://purl.org/dc/terms/`,
        },
      ]}
      meta={[
        {
          name: `author`,
          content: author ? author : site.siteMetadata.author.name,
        },
        {
          name: `description`,
          content: description,
        },
        {
          name: `keywords`,
          content: `Willow, Games, Video Games, Arise Sol, Scavenger`,
        },
        {
          property: `og:locale`,
          content: "en_US",
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: `https://www.willowgames.io/`,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.author.name,
        },
        {
          property: `og:type`,
          content: type,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `article:published_time`,
          content: date
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:site`,
          content: `@WillowScavenger`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author.twitterHandle,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          name: `robots`,
          content: `index, follow`,
        },
        {
          name: `dcterms.created`,
          content: date
        },
        {
          name: `copyright`,
          content: site.siteMetadata.author.name,
        },
        {
          name: `dcterms.rightsHolder`,
          content: site.siteMetadata.author.name,
        },
        {
          name: `dcterms.dateCopyrighted`,
          content: site.siteMetadata.copyrightDate,
        },
      ].concat(meta)}>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
