import React from "react"

import Invert from "../components/atoms/invert"
import Page from "../components/atoms/page"
import Nav from "../components/organisms/NEW_nav"
import CookiesBanner from "../components/organisms/cookies-banner"
import EmailOverlay from "../components/organisms/email-overlay"

import "./vertical-layout.scss"

export default function VerticalLayout({ children, scene, ...props }) {
  const { className, ...rest } = props
  return (
    <Page className={"vertical-layout" + (className && " " + className)} {...rest}>
      {scene()}
      <Invert />
      <main>
        <section>{children}</section>
      </main>
      <Nav />
      <EmailOverlay />
      <CookiesBanner />
    </Page>
  )
}
