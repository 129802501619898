import React from "react"

const Page = ({ children, ...props }) => {
  const { className, ...rest } = props
  return (
    <div
      className={"page" + (className && " " + className)}
      style={{ position: "relative" }}
      {...rest}>
      {children}
    </div>
  )
}

export default Page
