import React, { useState, useEffect, useRef } from "react"

import "./subscriber.scss"

export default function Subscriber({ onComplete, onError }) {
  const [subscriberState, setSubscriber] = useState({ email: "", name: "" })
  const [alert, setAlert] = useState("")
  const alertRef = useRef()

  const validateEmail = email => {
    let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm
    if (email.length !== 0)
      if (email.length < 72)
        if (email.length > 6)
          if (re.test(email)) return true
          else setAlert("Cuthulud|d9!1@$%f$a$a%.com... (Enter a valid email)")
        else setAlert("_@_.com (Your email is too short)")
      else
        setAlert(
          "thisisaveryveryveryVeryVERYVeRyVeRYlongemail@reallyReallyREALLYlongdomainname.com (Your email is too long)",
        )
    else setAlert("Email is required")
    return false
  }

  const validateName = name => {
    let re = /^[\w]*[ ]{1}[\w]+$/gm
    if (name.length !== 0)
      if (name.length < 72)
        if (name.length > 4)
          if (re.test(name)) return true
          else setAlert("Alphanumeric Names, please")
        else setAlert("Full Names, please")
      else setAlert("Hippopotomonstrosesquippedaliophobia is smaller... (Your name is too long)")
    else setAlert("Name is required")
    return false
  }

  const onInvalid = event => {
    event.preventDefault()
    setAlert(event.target.validationMessage)
  }

  useEffect(() => {
    if (alert === "") return
    let timeout = setTimeout(() => {
      alertRef.current.style = { opacity: 0 }
      setAlert("")
    }, 3000)
    alertRef.current.innerText = alert
    alertRef.current.style.opacity = 1
    return () => clearTimeout(timeout)
  }, [alert])

  const subscribeUser = async event => {
    event.preventDefault()

    if (!validateName(subscriberState.name) || !validateEmail(subscriberState.email)) return

    try {
      const init = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(subscriberState),
      }
      const res = await fetch(
        "https://g5wjs25050.execute-api.us-east-2.amazonaws.com/dev/subscriber",
        init,
      )
      const success = JSON.parse(await res.text())

      if (success && success.success) {
        setAlert(success.success)
        await new Promise(resolve => setTimeout(resolve, 2000))
        onComplete()
      }
    } catch (err) {
      setAlert("Oops, Something Borked...")
      await new Promise(resolve => setTimeout(resolve, 2000))
      onError()
    }
  }

  return (
    <div className='subscriber'>
      <h2>Subscribe</h2>
      <h3>
        Subscribe to our newsletter to receive news & updates via email. Trust us we promise not to
        spam your inbox. No one likes spam anyways.
      </h3>
      <p>Additionally, we wont sell your data.</p>
      <form onSubmit={subscribeUser}>
        <p className='alert' ref={alertRef}>
          {}
        </p>
        <label htmlFor='name'>Full Name</label>
        <input
          type='text'
          name='name'
          placeholder='John Smith'
          value={subscriberState.name}
          onChange={e => setSubscriber({ ...subscriberState, name: e.target.value })}
        />
        <label htmlFor='email'>Email</label>
        <input
          type='email'
          name='email'
          placeholder='johnsmith123@willowgames.io'
          value={subscriberState.email}
          onInvalid={onInvalid}
          onChange={e => setSubscriber({ ...subscriberState, email: e.target.value })}
        />
        <br />
        <input type='submit' value='Follow' title="Follow" />
      </form>
    </div>
  )
}
