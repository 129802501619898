import React, { useContext } from "react"
import { MobileTestContext } from "../../utils/support"
import "./invert.scss"

const mobileStyle = {
  background: "black",
  opacity: 0.6
}

const desktopStyle = {
  background: "grey",
  mixBlendMode: "difference",
}

const Invert = () => {
  const {isMobile} = useContext(MobileTestContext)
  return <div style={isMobile ? mobileStyle : desktopStyle} className='invert' />
}
export default Invert
