import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import "./NEW_nav.scss"

import styled from "@emotion/styled"

const Social = ({ link, className, title }) => (
  <a href={link} rel='noreferrer nofollow' target='_blank' className='social' title={title}>
    <div className={className} />
  </a>
)

const Socials = () => (
  <div className='socials'>
    <Social link='https://twitter.com/willowgamesdevs' className='twitter' title='Our Twitter.' />
    <Social
      link='https://www.reddit.com/user/WillowGamesDevs/'
      className='reddit'
      title='Our Reddit.'
    />
    {/*<Social link='https://discord.gg/vDfRxRC5uJ' className='discord' title='Our Discord.' />*/}
  </div>
)

const Background = styled.div`
  width: 100%;
  height: 100%;
  transform: translateZ(0);
  position: absolute;
  top: 0;
  right: 0;
  max-width: 600px;
  background: lightgrey;
`

const MenuToggle = ({ show, onClick }) => {
  return (
    <a
      href='#'
      role='button'
      className={"menu-toggle " + (show ? "close" : "open")}
      title={show ? "Close" : "Expand"}
      onClick={onClick}>
      <div className={show ? "close" : "open"}>+</div>
    </a>
  )
}

const MenuLink = ({ to, value }) => (
  <div className='menu-link'>
    <Link to={to} activeClassName='active' partiallyActive={true}>
      {value}
    </Link>
    <hr />
  </div>
)

const Navbar = ({ links }) => {
  return (
    <div className='navbar'>
      {links && links.map((l, i) => <MenuLink key={`menu-${i}`} to={l.link} value={l.name} />)}
    </div>
  )
}

const Menu = ({ show, children }) => {
  return <div className={`menu ${show ? "opened" : "closed"}`}>{children}</div>
}

export default function Nav({ children }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
      }
    `,
  )

  const [showMenu, setMenuVisibility] = useState(false)
  const [links, setLinks] = useState()
  const [title, setTitle] = useState()

  const menuLinks = data.site.siteMetadata.menuLinks

  useEffect(() => {
    const _links = JSON.parse(JSON.stringify(menuLinks))
    const pathName = location.pathname
    const activeLink = _links.find(ml => ml.link === pathName)
    if (activeLink) {
      _links.splice(_links.indexOf(activeLink), 1)
      setTitle(activeLink.name)
    }
    setLinks(_links)
  }, [data])

  return (
    <nav>
      <Background className={"menu-panel " + (showMenu ? "open" : "closed")} />
      <MenuToggle
        show={showMenu}
        onClick={e => {
          e.preventDefault()
          setMenuVisibility(!showMenu)
        }}
      />
      <Menu show={showMenu}>
        <h1>{title}</h1>
        <hr />
        <Navbar links={links} />
        {children && <hr />}
        {children}
        {children && <hr />}
        <Socials>
          <Social />
        </Socials>
        {!children && <div className='spacer' />}
        <p className='copyright'>© 2021 Willow Games</p>
      </Menu>
    </nav>
  )
}
