import React, { useState, useEffect } from "react"
import Subscriber from "./subscriber"

import "./email-overlay.scss"

const OverlayClose = ({ onClick }) => (
  <a href='#' role='button' className='overlay-close' title='Close' onClick={onClick}>
    <span>+</span>
  </a>
)

export default function EmailOverlay() {
  const [showOverlay, setOverlayVisibility] = useState(false)

  useEffect(() => {
    var timer
    if (!localStorage.getItem("closedEmail")) {
      timer = setTimeout(() => setOverlayVisibility(true), 2000)
    }
    return () => clearTimeout(timer)
  }, [])

  const close = () => {
    localStorage.setItem("closedEmail", "false")
    setOverlayVisibility(false)
  }

  return (
    <div className={"email-overlay " + (showOverlay ? "show" : "hide")}>
      <div className='fade' />
      <div className='container'>
        <Subscriber onComplete={close} onError={() => setOverlayVisibility(false)} />
        <OverlayClose onClick={close} />
      </div>
    </div>
  )
}
