import React, { useState, useEffect } from "react"

import "./cookies-banner.scss"

// TODO: Add GDPR Compliance...
// TODO: Change sessionStorage for localStorage...
export default function CookiesBanner() {
  const [showBanner, setBannerVisibility] = useState(false)

  useEffect(() => {
    var timer
    if (!localStorage.getItem("agree")) {
      timer = setTimeout(() => setBannerVisibility(true), 500)
    }
    return () => clearTimeout(timer)
  }, [])

  const handleAgreement = () => {
    localStorage.setItem("agree", "true")
    setBannerVisibility(false)
  }

  return (
    <div className={"cookies-banner " + (showBanner ? "show" : "hide")}>
      <small>
        Hi, we only use cookies purely for function!
        No Analytics, No creepy spyware, No BS!
        Just Click OK to kill this gross banner that is ruining our beautiful site!
      </small>
      <button title='OK' onClick={handleAgreement}>
        OK
      </button>
    </div>
  )
}
